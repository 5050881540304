/* General Styling */
.contact {
    padding-top: 2%;
    font-family: 'Roboto', sans-serif;
    background-color: #f9f9f9;
    color: #333;
    padding-bottom: 40px;
  }
  
  /* Header Section */
  .contact-header h2 {
    
    font-size: 2.5rem;
    color: #000; /* Black header */
    margin-bottom: 20px;
  }
  
  .contact-header p {
    font-size: 1.2rem;
    color: #555; /* Subtle grey for intro text */
  }
  

  .contact-page-title h1 {
    font-size: 2.5rem; /* Larger font for the title */
    font-weight: bold;
    color: #333; /* Dark text color */
    text-align: center; /* Center-align the title */
    padding: 20px 0; /* Add space above and below the title */
  }
  /* Contact Form Section */
  .contact-form-section {
    background-color: #fff;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .contact-form .form-group {
    margin-bottom: 20px;
  }
  
  .contact-form .form-group label {
    display: block;
    font-size: 1rem;
    margin-bottom: 8px;
    color: #333;
  }
  
  .contact-form .form-control {
    width: 100%;
    padding: 10px 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1rem;
    color: #333;
    background-color: #f9f9f9;
  }
  
  .contact-form .form-control:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.3);
    outline: none;
  }
  
  .contact-form .btn-primary {
    background-color: #007bff;
    color: white;
    padding: 15px 30px;
    font-size: 1rem;
    border: none;
    border-radius: 50px;
    text-transform: uppercase;
    font-weight: bold;
    transition: background 0.3s ease, transform 0.3s ease;
    cursor: pointer;
    width: 100%;
  }
  
  .contact-form .btn-primary:hover {
    background-color: #0056b3;
    transform: scale(1.05);
  }
  
  /* Contact Info Section */
  .contact-info-section {
    margin-top: 40px;
  }
  
  .contact-info-section h3 {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #000;
  }
  
  .contact-info-section p {
    font-size: 1.2rem;
    color: #555;
    margin-bottom: 10px;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .contact-header h2 {
      font-size: 2rem;
    }
  
    .contact-form .btn-primary {
      font-size: 0.9rem;
    }
  
    .contact-info-section h3 {
      font-size: 1.5rem;
    }

  }

  
  