.about {
    font-family: 'Arial', sans-serif;
    color: #333;
  }
  
  /* Header Section */
  .about-header {
    background: #1e1e1e;
    color: #fff;
    padding: 40px 0;
  }
  
  .about-header h2 {
    font-size: 2.5rem;
    margin-bottom: 10px;
  }
  
  .about-header .tagline {
    font-style: italic;
    font-size: 1.2rem;
  }
  
  /* Section Titles */
  h3 {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  /* Who We Are Section */
  .who-we-are img {
    max-width: 100%;
    border-radius: 8px;
  }
  
  /* Our Mission Section */
  .our-mission {
    background: #f9f9f9;
    padding: 40px 20px;
  }
  
  .our-mission p {
    line-height: 1.6;
    margin-bottom: 20px;
  }
  
  /* Why Choose Us Section */
  .why-choose-us .choose-card {
    background: #f3f3f3;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .why-choose-us .choose-card h5 {
    font-size: 1.3rem;
    margin-bottom: 10px;
  }
  
  /* Testimonials Section */
  .testimonials {
    padding: 40px 20px;
    background: #f9f9f9;
  }
  
  .testimonials .testimonial-card {
    background: #fff;
    border: 1px solid #e6e6e6;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-bottom: 20px;
  }
  
  .testimonials .testimonial-card small {
    display: block;
    margin-top: 10px;
    color: #666;
  }
  
  /* Utilities */
  .text-center {
    text-align: center;
  }
  
  .py-5 {
    padding: 3rem 0;
  }
  
  .bg-light {
    background: #f9f9f9;
  }
  
  .container {
    max-width: 1100px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .row {
    display: flex;
    flex-wrap: wrap;
  }
  
  .col-md-6 {
    width: 50%;
    padding: 15px;
  }
  
  .col-md-4 {
    width: 33.33%;
    padding: 15px;
  }
  
  img {
    max-width: 100%;
    height: auto;
  }
  
  .cta-section {
    background-color: #f8f9fa;
    padding-bottom: 30px;
  }
  
  .cta-text {
    font-size: 20px;
    color: #333;
    line-height: 1.5;
  }
  
  .cta-text .cta-link {
    color: #007bff;
    text-decoration: none;
  }
  
  .cta-text .cta-link:hover {
    text-decoration: underline;
    color: #004fa2;
  }
  
  .cta-text .cta-link:focus {
    color: #004fa2;
    outline: none;
  }
  