/* Main Banner Styles */
.main-banner {
  background-color: #333; /* Dark Grey Background */
  background-size: cover;
  background-position: center;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.main-banner .container {
  z-index: 2;
}

.main-banner h2 {
  font-size: 3rem;
  margin-bottom: 10px;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7);
}

.main-banner p {
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.main-banner .btn-primary {
  background-color: #e74c3c; /* Red Button for CTA */
  color: white;
  padding: 15px 30px;
  font-size: 1.1rem;
  border: none;
  border-radius: 50px;
  text-transform: uppercase;
  font-weight: bold;
  transition: background 0.3s ease, transform 0.3s ease;
}

.main-banner .btn-primary:hover {
  background-color: #c0392b;
  transform: scale(1.05);
}

/* About Section */
.about-section {
  background-color: #ffffff;
  color: #333;
}

.about-section h3 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.about-section img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
}

/* Services Section */
.services-section {
  background-color: #f4f4f4; /* Light Grey Background */
}

.service-card {
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.service-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

/* Testimonials Section */
.testimonials-section {
  background-color: #ffffff;
}

.testimonial-card {
  background: #f4f4f4;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  font-size: 1.1rem;
  color: #333;
}

.testimonial-card p {
  font-style: italic;
}

.testimonial-card small {
  font-size: 0.9rem;
  color: #777;
}

/* Contact Section */
.contact-section {
  background-color: #f4f4f4;
  color: #333;
}

.contact-section .btn-primary {
  background-color: #e74c3c; /* Red Button for CTA */
  color: white;
  padding: 15px 30px;
  font-size: 1.1rem;
  border: none;
  border-radius: 50px;
  text-transform: uppercase;
  font-weight: bold;
  transition: background 0.3s ease, transform 0.3s ease;
}

.contact-section .btn-primary:hover {
  background-color: #c0392b;
  transform: scale(1.05);
}

/* Responsive Styles */
@media (max-width: 768px) {
  .main-banner h2 {
    font-size: 2.2rem;
  }

  .about-section h3 {
    font-size: 2rem;
  }

  .service-card,
  .testimonial-card {
    margin-bottom: 20px;
  }

  .services-section .row,
  .testimonials-section .row {
    text-align: center;
  }
}

/* Responsive styles for mobile view */
@media (max-width: 768px) {
  .main-banner h2 {
    font-size: 1.5rem;
  }

  .main-banner p {
    font-size: 1rem;
  }

  .btn-primary {
    font-size: 0.9rem;
    padding: 10px 15px;
  }

  .areas-we-service .row {
    flex-direction: column;
  }

  .areas-we-service .col-md-6 {
    margin-bottom: 20px;
  }

  .map-container iframe {
    height: 250px;
  }

  .services-section .row {
    flex-direction: column;
  }

  .service-card {
    margin-bottom: 20px;
  }
}

/* Extra small screen adjustments */
@media (max-width: 576px) {
  .main-banner h2 {
    font-size: 1.2rem;
  }

  .main-banner p {
    font-size: 0.9rem;
  }

  .btn-primary {
    font-size: 0.8rem;
    padding: 8px 12px;
  }

  .contact-section {
    padding: 20px;
  }
}

/* Styles for mobile-friendly class when zoomed */
.mobile-friendly .main-banner h2 {
  font-size: 1.5rem;
}

.mobile-friendly .main-banner p {
  font-size: 1rem;
}

.mobile-friendly .map-container iframe {
  height: 200px;
}

.mobile-friendly .service-card {
  margin-bottom: 15px;
}
