/* Ensure html takes up the full height */

/* General styles */
body {
  font-family: 'Roboto', sans-serif;
  background-color: #000000;
  color: #333;
  margin: 0;
  padding: 0;
  display: flex;          /* Set flexbox for body */
  flex-direction: column; /* Stack content vertically */
}

.App {
  display: flex;
  flex-direction: column;
  flex-grow: 1;  /* Make sure the main content fills available space */
}

/* Header Styles */
header {
  background-color: #1c1e21;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 1000;
}

header nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 50px;
}

header nav .logo {
  max-width: 350px;
}

header nav ul {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

header nav ul li {
  margin-left: 20px;
}

/* Font for Rage Italic */
@font-face {
  font-family: 'Rage Italic';
  src: url('../public/fonts/RAGE.TTF') format('truetype');
  font-weight: normal;
  font-style: normal;
}

header nav ul li a {
  font-family: 'Dancing Script', cursive;
  font-size: 1.4rem;
  color: #ffffff;
  text-transform: none;
  letter-spacing: 2px;
  transition: color 0.3s ease-in-out;
}

/* Example: applying various cursive fonts to different logos */
.logo-dancing-script {
  font-family: 'Dancing Script', cursive;
  font-size: 3rem;
}


header nav ul li a:hover {
  color: #f1c40f;
}

/* Main Banner Styles */
.main-banner {
  background-color: #1c1e21;
  background-size: cover;
  background-position: center;
  position: relative;
  height: 500px;
  color: white;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-banner::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Dark overlay for better contrast */
  z-index: 1;
}

.main-banner div {
  z-index: 2;
}

.main-banner h2 {
  font-size: 3rem;
  margin-bottom: 20px;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7);
}

.main-banner p {
  font-size: 1.2rem;
  margin-bottom: 30px;
}

.main-banner .btn-primary {
  background-color: #007bff;
  color: white;
  padding: 15px 30px;
  font-size: 1rem;
  border: none;
  border-radius: 50px;
  text-transform: uppercase;
  font-weight: bold;
  transition: background 0.3s ease, transform 0.3s ease;
}

.main-banner .btn-primary:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

/* Section Styles */
section {
  padding: 60px 50px;
  background: #ffffff;
  margin-bottom: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

section h2 {
  font-size: 2rem;
  text-align: center;
  margin-bottom: 40px;
  color: #333;
}

section p {
  font-size: 1rem;
  color: #666;
  line-height: 1.8;
}

.card {
  background: #ffffff;
  border: none;
  border-radius: 10px;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  margin-bottom: 30px;
}

.card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.card-body {
  padding: 20px;
}

.card-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.card-text {
  color: #666;
  font-size: 1rem;
  line-height: 1.6;
}

/* Footer Styles */
footer {
  background-color: #1c1e21;
  color: white;
  margin-top: auto;
  position:fixed;
  bottom:0;
  left:0;
  text-align: center;
  padding: 10px 0; /* Increased padding for a thicker footer */
  font-size: 1rem; /* Use rem for consistent scaling */
  height: 2rem; /* Fixed height to control size fluctuations */
  max-height: 2.5rem; /* Prevents zoom-induced height increase */
  min-height: 2.5rem; /* Ensures consistency */
  width: 100%;
}

footer p {
  margin: 0;
  font-size: 1rem;
}

@media (max-width: 768px) {
  footer {
    font-size: 0.9rem; /* Slightly smaller text on smaller screens */
    padding: 10px ; /* Reduce padding for smaller screens */
  }
}

footer .social-icons {
  margin-top: 10px;
}

footer .social-icons a {
  color: white;
  margin: 0 10px;
  font-size: 1.2rem;
  transition: color 0.3s ease;
}

footer .social-icons a:hover {
  color: #007bff;
}

/* Navbar Styles */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px; /* Increased padding for a thicker navbar */
  position: sticky;
  top: 0;
  z-index: 1000;
  height: 4rem; /* Fixed height to control size fluctuations */
  max-height: 4rem; /* Prevents zoom-induced height increase */
  min-height: 4rem; /* Ensures consistency */
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.logo-container .logo {
  margin-top: 1%;
  height: 55px; /* Adjust the logo size slightly */
}

.menu-toggle {
  background: none;
  border: none;
  font-size: 1.8rem;
  color: white;
  cursor: pointer;
  display: none; /* Hidden on larger screens */
}

.nav-menu {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 1rem; /* Set font size using rem for consistency */
}

.nav-menu li {
  margin: 0 20px; /* Add more spacing for a cleaner look */
}

.nav-menu li a {
  text-decoration: none;
  color: white;
  font-size: 1.1rem; /* Slightly larger for readability */
  font-weight: bold; /* Make the text slightly bolder */
}

.nav-menu li a:hover {
  text-decoration: underline;
}

/* Responsive Styles */
@media (max-width: 768px) {
  /* Toggle Button */
  .menu-toggle {
    display: block; /* Show toggle button on smaller screens */
    background: none;
    border: none;
    font-size: 2rem;
    color: white;
    cursor: pointer;
    position: absolute;
    top: 15px;
    right: 20px;
    z-index: 1000; /* Keep toggle on top */
    transition: color 0.3s ease, transform 0.3s ease;
  }

  .menu-toggle:hover {
    color: #f1c40f; /* Gold hover effect */
    transform: scale(1.1); /* Slight zoom for interactivity */
  }

  /* Navigation Menu Container */
  .nav-menu {
    display: none; /* Initially hidden */
    flex-direction: column;
    position: fixed;
    top: 60px; /* Below the header */
    left: 50%;
    transform: translateX(-50%); /* Center the menu horizontally */
    background-color: #1c1e21; /* Dark background */
    width: 90%; /* Take most of the screen width */
    max-width: 400px; /* Prevent it from becoming too wide */
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.5); /* Add a clean shadow effect */
    padding: 20px; /* Add padding for spacing inside */
    border-radius: 10px; /* Smooth rounded corners */
    z-index: 999; /* Ensure it appears above content */
    opacity: 0; /* Initial state for fade-in animation */
    transition: opacity 0.4s ease, transform 0.4s ease;
  }

  /* Open Menu Animation */
  .nav-menu.open {
    display: flex; /* Show the menu */
    opacity: 1; /* Fade in */
    transform: translateX(-50%) translateY(0); /* Slide in */
  }

  /* Menu Items */
  .nav-menu li {
    margin: 15px 0; /* Spacing between items */
    text-align: center; /* Center the text */
  }

  /* Menu Links */
  .nav-menu a {
    font-size: 2.5rem; /* Larger and more readable text */
    font-weight: 600; /* Slightly bolder font */
    padding: 15px 25px; /* Add padding around text for better touch targets */
    text-align: center;
    color: white; /* White text color */
    text-decoration: none; /* Remove underline */
    display: block; /* Full width for easier clicks */
    border-radius: 5px; /* Rounded button-like look */
    transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;
  }

  .nav-menu a:hover {
    background-color: #333; /* Slightly darker background */
    color: #f1c40f; /* Gold text on hover */
    transform: scale(1.05); /* Slight zoom for hover feedback */
  }

  /* Menu Close Animation */
  .nav-menu:not(.open) {
    transform: translateX(-50%) translateY(-20px); /* Slide up when closed */
  }
}


/* Limit Zoom Fluctuations */
html {
  font-size: 16px; /* Use a root font size */
}

body {
  font-size: 1rem; /* Prevent drastic scaling on zoom */
}

.navbar {
  font-size: 1rem; /* Fix the navbar font size */
}

.menu-toggle {
  color: rgb(255, 255, 255);
  font-size: 1.8rem;
  line-height: 1;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
}

/* Scale font sizes slightly */
@media (max-width: 1024px) {
  .nav-menu li a {
    font-size: 1rem;
  }
}

@media (max-width: 768px) {
  .nav-menu li a {
    font-size: 0.9rem;
  }
}