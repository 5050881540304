/* General Styling */
.services {
    font-family: 'Roboto', sans-serif;
    color: #333; /* Dark text for contrast */
    background-color: #f9f9f9; /* Light background */
    padding-bottom: 40px;
  }
  
  .services-header h2 {
    font-size: 2.5rem;
    color: #000; /* Black header */
    margin-bottom: 20px;
  }
  
  .services-header p {
    font-size: 1.2rem;
    color: #000000; /* Subtle grey for intro text */
  }
  
  .services-list {
    margin-top: 30px;
  }
  
  .service-card {
    background-color: #ffffff; /* White cards */
    border: 1px solid #ddd; /* Subtle border for separation */
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    color: #333; /* Dark text */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .service-card h3 {
    font-size: 1.5rem;
    color: #000; /* Black for headings */
    margin-bottom: 10px;
  }
  
  .service-card p {
    font-size: 1rem;
    color: #555; /* Grey for readability */
  }
  
  .service-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2); /* More prominent hover shadow */
  }
  
  /* Call-to-Action Section */
  .services-cta {
    background-color: #ffffff; /* Light grey background */
    padding: 40px 20px;
    color: #333; /* Dark text */
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .services-cta h3 {
    font-size: 2rem;
    margin-bottom: 15px;
    color: #000; /* Black heading */
  }
  
  .services-cta p {
    font-size: 1.2rem;
    color: #555; /* Grey for CTA text */
    margin-bottom: 20px;
  }
  
  .services-cta .btn-primary {
    background-color: #e74c3c; /* Blue button */
    color: white;
    padding: 15px 30px;
    font-size: 1rem;
    border: none;
    border-radius: 50px;
    text-transform: uppercase;
    font-weight: bold;
    transition: background 0.3s ease, transform 0.3s ease;
  }
  
  .services-cta .btn-primary:hover {
    background-color: #c0392b; /* Darker blue on hover */
    transform: scale(1.05);
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .services-header h2 {
      font-size: 2rem;
    }
  
    .services-cta h3 {
      font-size: 1.5rem;
    }
  }

  /* Section Styling for Custom Services List */
.custom-services-list {
    text-align: center;
    padding: 60px 20px;
    background-color: #ffffff; /* Light gray background for distinction */
    margin-top: 0.5%;
  }
  
  /* Header Styling */
  .custom-services-header-title {
    font-size: 1.9rem; /* Large font size for desktop */
    font-weight: bold;
    color: #000000; /* Dark, professional color */
    margin-bottom: 25px;
    line-height: 1.2;
  }
  
  /* Subheading Styling */
  .custom-services-header-subtitle {
    font-size: 1.0rem; /* Slightly larger text for better readability */
    line-height: 1.8; /* Add line spacing for clarity */
    color: #7f8c8d; /* Lighter, complementary color */
    max-width: 800px;
    margin: 0 auto;
    padding: 0 20px;
    font-weight: 400;
  }
  
  /* Responsive Adjustments for Smaller Screens */
  @media (max-width: 768px) {
    .custom-services-header-title {
      font-size: 2.2rem; /* Smaller title font for mobile */
    }
  
    .custom-services-header-subtitle {
      font-size: 1.125rem; /* Slightly smaller subtitle text for mobile */
    }
  }
  
  /* Extra mobile responsiveness for smaller devices */
  @media (max-width: 480px) {
    .custom-services-header-title {
      font-size: 1.8rem; /* Even smaller font on very small screens */
    }
  
    .custom-services-header-subtitle {
      font-size: 1rem; /* Subtitle adjusted for small devices */
      padding: 0 10px; /* Reduce padding for smaller devices */
    }
  }
  
